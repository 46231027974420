<template>
  <div>
    <div class="container p-3" style="max-width: 720px !important">
      <router-view v-slot="{ Component }">
        <transition name="deslize" mode="out-in">
          <component :key="$route.path" :is="Component"></component>
        </transition>
      </router-view>
    </div>
    <ModalCirculoCarregando />
  </div>
</template>

<script>
import ModalCirculoCarregando from "@/components/shared/modais/ModalCirculoCarregando.vue";

export default {
  components: {
    ModalCirculoCarregando,
  },
};
</script>


<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--cor-tema-fundo);
}

.deslize-enter-active,
.deslize-leave-active {
  transition: opacity 0.5s, transform 0.6s;
}

.deslize-enter-from,
.deslize-leave-to {
  opacity: 0;
  transform: translateX(-20%);
}

/* Remove sombra em botão com foco */
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
  opacity: 0.8;
}
</style>
