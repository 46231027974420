import { createRouter, createWebHistory } from "vue-router"

const rotas = [
  {
    path: "/loja-nao-encontrada",
    name: "loja-desconhecida",
    component: () => import(/* webpackChunkName: "loja-desconhecida" */ "@/views/LojaDesconhecida.vue"),
  },
  {
    path: "/lojas",
    name: "lojas",
    component: () => import(/* webpackChunkName: "lojas" */ "@/views/Lojas.vue"),
    props: rota => ({ ...rota.params }),
  },

  {
    path: "/:id",
    redirect: { name: "loja" },
  },
  {
    path: "/loja/:id",
    name: "loja",
    component: () => import(/* webpackChunkName: "loja" */ "@/views/Loja.vue"),
    props: true,
  },

  {
    path: "/loja/:id/produto/:idMercadoria/:slugMercadoria",
    name: "mercadoria",
    component: () => import(/* webpackChunkName: "mercadoria" */ "@/views/Mercadoria.vue"),
    props: rota => ({ ...rota.params, idMercadoria: parseInt(rota.params.idMercadoria) }),
  },

  {
    path: "/loja/:id/novo-pedido",
    name: "pedido-novo",
    component: () => import(/* webpackChunkName: "pedido-novo" */ "@/views/NovoPedido.vue"),
    props: rota => ({ ...rota.params }),
  },

  {
    path: "/loja/:id/pedido-realizado",
    name: "pedido-realizado",
    component: () => import(/* webpackChunkName: "pedido-realizado" */ "@/views/PedidoRealizado.vue"),
    props: rota => ({ ...rota.params }),
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "loja-desconhecida" },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: rotas,
})

export default router