import { createApp } from "vue";
import store from "./store";
import router from "./router";
import { createHead } from "@vueuse/head";
import Maska from "maska";
import Toast, { POSITION } from "vue-toastification";
import VueGtag from "vue-gtag";
import App from "./App.vue";

const head = createHead();
const app = createApp(App)
    .use(store)
    .use(router)
    .use(head)
    .use(Maska)
    .use(Toast, {
        position: POSITION.TOP_RIGHT,
        timeout: 3000,
    }).use(VueGtag, {
        config: { id: "G-3XFE7416QD" },
        router,
    });
app.directive("visible", function (el, binding) {
    el.style.visibility = binding.value ? "visible" : "hidden";
});
app.mount("#app");

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-toastification/dist/index.css";