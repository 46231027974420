import { createStore } from "vuex";
import { SALVA_LOJA, ADICIONA_ITEM, REMOVE_ITEM, DETALHA_PEDIDO, } from "./mutacoes.js"

const store = createStore({
    state() {
        return {
            loja: {
                tema: "padrao",
            },
            pedido: {
                id: undefined,
                cliente: "",
                contato: "",
                email: "",
                endereco: "",
                comentario: "",
                itens: [],
            },
        };
    },
    getters: {
        buscaItem: (state) => (itemBuscado) => {
            return state.pedido.itens.find(item => item.id == itemBuscado.id);
        },
        total: (state) => state.pedido.itens.reduce(
            (acumulador, item) => acumulador + item.quantidade * item.valor, 0.0
        ).toFixed(2),
    },
    mutations: {
        [SALVA_LOJA](state, params) {
            state.loja = {
                tema: params.tema,
                identificador: params.identificador,
                nome: params.nome,
                descricao: params.descricao,
                logotipo: params.logotipo,
                endereco: params.endereco,
                site: params.site,
                email: params.email,
                celular: params.celular,
                telefone: params.telefone,
                mercadorias: params.mercadorias,
            };
        },
        [ADICIONA_ITEM](state, params) {
            const item = this.getters.buscaItem(params);
            if (!item) {
                state.pedido.itens.push({
                    ...params,
                    quantidade: 1
                });
            } else {
                item.quantidade += 1;
            }
        },
        [REMOVE_ITEM](state, params) {
            const item = this.getters.buscaItem(params);
            if (!item) return;

            item.quantidade -= 1;
            if (item.quantidade === 0) {
                state.pedido.itens.splice(state.pedido.itens.indexOf(item), 1);
            }
        },
        [DETALHA_PEDIDO](state, params) {
            state.pedido.id = params?.id;
            state.pedido.cliente = params.cliente;
            state.pedido.contato = params.contato;
            state.pedido.email = params.email;
            state.pedido.endereco = params.endereco;
            state.pedido.comentario = params.comentario;
        },
    },
});

export default store