<template>
  <div
    class="modal"
    id="modal-circulo-carregando"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="modalCirculoCarregandoLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered d-flex justify-content-center"
    >
      <div class="spinner-border circulo-animado" role="status">
        <span class="visually-hidden">Carregando...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCirculoCarregando",
  data() {
    return {};
  },
};
</script>

<style scoped>
.circulo-animado {
  width: 3rem;
  height: 3rem;
  border-width: 0.4rem;
  color: #47b3c1;
}
</style>